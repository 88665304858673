import React, { useState, useContext, useEffect } from 'react';
import { TableContext } from '../context';
import '../App.css';
import { FaTimes } from 'react-icons/fa'; // Import the "X" icon from react-icons

/**
 * Search component for searching application names.
 * Provides suggestions based on user input and allows fetching table data.
 */
function Search() {
  const { search, setSearch } = useContext(TableContext);
  const [suggestions, setSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState(''); // State to track input value
  const [shouldFetchSuggestions, setShouldFetchSuggestions] = useState(true);
  const [isLoading, setIsLoading] = useState(false); // State to track loading
  
  /**
   * Handles the change of the input value.
   * @param {React.ChangeEvent<HTMLInputElement>} event - The change event.
   */
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setShouldFetchSuggestions(true); // Allow fetching suggestions again
  };

  /**
   * Handles the click event of the search button.
   * Fetches table data and sets loading state.
   */
  const handleButtonClick = () => {
    setSearch(inputValue); // Set search value
  };

  /**
   * Handles the click event of a suggestion.
   * Sets the input value to the selected suggestion, clears suggestions, and triggers search.
   * @param {string} suggestion - The selected suggestion.
   */
  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion);
    setSearch(suggestion); // Set search value
    setSuggestions([]); // Clear suggestions after selection
    setShouldFetchSuggestions(false); // Prevent fetching suggestions until user types again
  };

  /**
   * Handles the click event of the "X" icon.
   * Clears the input value and triggers a search.
   */
  const handleClearClick = () => {
    setInputValue('');
    setSearch(''); // Clear search value
    setSuggestions([]); // Clear suggestions
    setShouldFetchSuggestions(false); // Prevent fetching suggestions until user types again
  };

  useEffect(() => {
    /**
     * Fetches suggestions based on the input value.
     */
    const fetchSuggestions = async () => {
      if (!shouldFetchSuggestions || inputValue.trim() === '') {
        setSuggestions([]);
        return;
      }

      setIsLoading(true); // Set loading to true before fetching suggestions

      try {
        const response = await fetch('https://flux-backend.azurewebsites.net/getSuggestions', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: "Bearer " + localStorage.getItem("jwt_token"),
          },
          body: JSON.stringify({ app_name: inputValue }),
        });

        const data = await response.json();
        if (data.status === 200) {
          setSuggestions(data.data || []);
        } else {
          setSuggestions([]);
        }
      } catch (error) {
        console.error('Error fetching suggestions:', error);
        setSuggestions([]);
      } finally {
        setIsLoading(false); // Set loading to false after fetching suggestions
      }
    };

    fetchSuggestions();
  }, [inputValue, shouldFetchSuggestions]);

  return (
    <div className="search-container">
      <div className="search-bar">
        <h3 className="search-label">Application Name:</h3>
        <div className='search-Input_suggestions'>
          <input
            className="search-input"
            type="text"
            placeholder="Application Name"
            value={inputValue}
            onChange={handleInputChange}
          />
          {inputValue && (
            <FaTimes 
              className="clear-icon" 
              onClick={handleClearClick} 
            />
          )}
          {isLoading ? (
            <ul className="suggestions-dropdown">
              <li className="suggestion-item">Loading...</li>
            </ul>
          ) : (
            suggestions.length > 0 && (
              <ul className="suggestions-dropdown">
                {suggestions.map((suggestion, index) => (
                  <li 
                    key={index} 
                    className="suggestion-item"
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            )
          )}
        </div>
        <div className="tooltip-container">
          <button 
            onClick={handleButtonClick} 
            className="animated-search-button"
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
}

export default Search;